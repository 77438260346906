import React, { useContext, useEffect, useState } from 'react'
import { Stack, Box, Typography, Paper } from '@mui/material';
import SimpleAccordion from '../components/Accordion';
import { MyContext } from '../router/router';
import { BaseAccordion, BaseButton, BaseGrid } from 'reidea-common';
import backgroundImage from  './../img/background.png'
import TitleColumn from '../components/Title/Column';
import { UseQueryResult } from 'react-query';
import { useDeleteProject, useGetProjects, usePutProject } from '../components/API/projects';
import { useGetCompanies } from '../components/API/companies';
import { useSearchParams } from 'react-router-dom';
import { useGetSections, usePutSection } from '../components/API/sections';
import { simple as simple_project } from '../interface/project';
import { SectionType, simple as simple_section } from '../interface/section';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SouthIcon from '@mui/icons-material/South';
import LanguageIcon from '@mui/icons-material/Language';
import SectionAccordion from '../components/Accordion/TextSectionAccordion';
import TextSectionAccordion from '../components/Accordion/TextSectionAccordion';

interface Props {
  isMobile : boolean;
}


const Project = ({isMobile}: Props) => {
  const { subTitleFontSize, mainFontSize, contentRatio, isXS, isSmall, headerSize } = useContext(MyContext);
  
  // ルームID
  const [searchParams] = useSearchParams();
  const project_id :string | null = searchParams.get(`projectID`) ?? null

  const { data: projects, queryKey:projects_queryKey, isLoading: isLoading_projects } : UseQueryResult<simple_project[], Error> & { queryKey: string } = useGetProjects("projects", project_id);
  const { data: sections, queryKey:sections_queryKey, isLoading: isLoading_sections } : UseQueryResult<simple_section[], Error> & { queryKey: string } = useGetSections("sections", "project_id="+project_id);
  const [company_name, setCompanyName] = useState<string>("")
  const [project, setProject] = useState<simple_project | null>(null)
  useEffect(()=>{
    if(projects && projects.length === 1){
      setCompanyName(projects[0]?.company?.name)
      setProject(projects[0])
    }
  },[projects])

  const putMutation = usePutSection();
  const putMutationProject = usePutProject();
  const deleteMutationProject = useDeleteProject();
  const onCreate = async () => {
    try {
      if(project_id){
        await putMutation.mutate({
            newValues: {
              project_id: project_id,
              text: "",
              title: ""
            },
        },
        {onSuccess: () => {window.location.reload()}});
      }
    } catch (error) {
        console.error('Failed to update like status:', error);
    }
  }
  const onDelete = () => {
    if(project_id){
      deleteMutationProject.mutate(project_id,{onSuccess:async()=>{
        window.location.href = "/projects"
      }})
    }
  }

  const source_section = (sections??[]).find((section: simple_section) => section.type as unknown as string === 'source')
  const mtg_section = (sections??[]).find((section: simple_section) => section.type as unknown as string === 'mtg')
  const return_section = (sections??[]).find((section: simple_section) => section.type as unknown as string === 'return')
  const description_section = (sections??[]).find((section: simple_section) => section.type as unknown as string === 'description')
  const schedule_section = (sections??[]).find((section: simple_section) => section.type as unknown as string === 'schedule')
  const image_section = (sections??[]).find((section: simple_section) => section.type as unknown as string === 'image')
  const video_section = (sections??[]).find((section: simple_section) => section.type as unknown as string === 'video')


  return (
    <Stack bgcolor={"snow"} mt={headerSize+"px"} zIndex={2} minHeight={1000} pt={2} pb={5} alignItems={'center'} position={'relative'} spacing={4}>

        <Stack alignItems={"center"} width={contentRatio*100+"%"} zIndex={2} direction={"row"} justifyContent={"space-between"}>
          <Stack onClick={()=>{
            window.location.href="/projects"
          }} sx={{cursor:"pointer"}} spacing={1} direction={"row"} alignItems={"center"}>
            <NavigateBeforeIcon sx={{color:"#383E86"}} />
            <Typography color={"#383E86"} fontWeight={600} fontSize={mainFontSize} letterSpacing={1.2} >
                {"プロジェクト一覧"}
            </Typography>
          </Stack>
          <BaseButton height='30px' px={3} color='red' backgroundColor='white' border='1px solid red' text='このプロジェクトを削除'
            onClick={()=>{onDelete()}}
          />
        </Stack>


        {/* メインコンポーネントをここに記述 */}
        <Stack spacing={2} bgcolor={"white"} pt={4} px={6} pb={10} width={contentRatio*100+"%"} alignItems={"center"} >
          <TitleColumn title={''} title_en={project?.name ?? "PROJECT"} fontSize={subTitleFontSize+5}/>
              
          <Stack width={"95%"} spacing={2} p={3} bgcolor={"white"} boxShadow={'0 6px 8px rgba(0, 0, 0, 0.15)'} borderRadius={2} >
            <Stack direction={"row"} alignItems={"center"} spacing={1} >
              <LanguageIcon sx={{fontSize:mainFontSize+2, color:"#383E86"}}></LanguageIcon>
              <Typography color={"#383E86"} fontWeight={600} fontSize={mainFontSize+2} letterSpacing={1.2} >
                {"目次"}
              </Typography>
            </Stack>
            <BaseGrid cols={3} spacingX={1} spacingY={1} items={sections?.map((section:simple_section) => (
              <Stack px={1.5} py={1} direction={"row"} justifyContent={"space-between"} borderRadius={2} alignItems={"center"} bgcolor={"white"} border={"0.5px solid lightgrey"}
                sx={{cursor:"pointer", ":hover":{boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'}}} onClick={()=>{
                
                }}>
                <Typography color={"#363636"} fontWeight={200} fontSize={mainFontSize-2} letterSpacing={1.2} >
                  {section.title}
                </Typography>
                <SouthIcon sx={{fontSize:mainFontSize-3, color:"grey"}}></SouthIcon>

              </Stack>
            )) ?? []} />
          </Stack>

          <Stack direction={"row"} width={"95%"} py={4} >
            <BaseGrid cols={1} spacingY={4} animation enableAnimation items={[
              <Stack direction={"row"} justifyContent={"space-between"} alignItems="flex-start">
                <TextSectionAccordion title={source_section?.title ?? '資料'} section_id={source_section?.section_id ?? ""}
                  preveiw={source_section?.text ?? "クリックで詳細を表示"}
                  details={source_section?.text ?? "まだ内容がありません"}
                 width={"49%"} />
                <TextSectionAccordion title={mtg_section?.title ?? 'mtg内容'} section_id={mtg_section?.section_id ?? ""}
                  preveiw={"クリックで詳細を表示"}
                  details={mtg_section?.text ?? "まだ内容がありません"}
                 width={"49%"} />
              </Stack>,
              <TextSectionAccordion title={return_section?.title ?? 'リターン内容'} section_id={return_section?.section_id ?? ""}
                preveiw={return_section?.text ?? "クリックで詳細を表示"}
                details={return_section?.text ?? "まだ内容がありません"}
                width={"100%"} />,
              <TextSectionAccordion title={description_section?.title ?? '説明'} section_id={description_section?.section_id ?? ""}
                preveiw={description_section?.text ?? "まだ内容がありません"}
                details={description_section?.text ?? "まだ内容がありません"}
                width={"100%"} />,
              <TextSectionAccordion title={schedule_section?.title ?? 'スケジュール'} section_id={schedule_section?.section_id ?? ""}
                preveiw={schedule_section?.text ?? "まだ内容がありません"}
                details={schedule_section?.text ?? "まだ内容がありません"}
                width={"100%"} />,
              <Stack direction={"row"} justifyContent={"space-between"}>
                <TextSectionAccordion title={image_section?.title ?? '掲載画像URL'} section_id={image_section?.section_id ?? ""}
                  preveiw={image_section?.text ?? "まだ内容がありません"}
                  details={image_section?.text ?? "まだ内容がありません"}
                 width={"49%"} />
                <TextSectionAccordion title={video_section?.title ?? '掲載動画URL'} section_id={video_section?.section_id ?? ""}
                  preveiw={video_section?.text ?? "まだ内容がありません"}
                  details={video_section?.text ?? "まだ内容がありません"}
                 width={"49%"} />
              </Stack>,

            ]} />
          </Stack>

          <BaseButton color='#383E86' backgroundColor='white' border='1px solid #383E86'
            text='+ 新しいセクションを追加' height='50px' width='40%' bold fontSize={mainFontSize} 
            onClick={()=>{onCreate()}}
          />
        </Stack>

       
    </Stack>
  )
}

export default Project;
