import React, { useContext, useEffect, useState } from 'react'
import { Stack, Box, Typography, Paper, TableContainer, Table, TableBody, TableRow, TableCell, Button } from '@mui/material';
import SimpleAccordion from '../components/Accordion';
import { MyContext } from '../router/router';
import { BaseButton, BaseGrid, BaseTable, BaseTextField } from 'reidea-common';
import backgroundImage from  './../img/background.png'
import TitleColumn from '../components/Title/Column';
import { UseQueryResult } from 'react-query';
import { useDeleteProject, useGetProjects, usePutProject } from '../components/API/projects';
import { useDeleteCompany, useGetCompanies, usePutCompany } from '../components/API/companies';
import { useSearchParams } from 'react-router-dom';
import { useGetSections, usePutSection } from '../components/API/sections';
import { simple } from '../interface/company';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import EditIcon from '@mui/icons-material/Edit';
import { RowProps } from 'reidea-common/dist/Table/interface/base';

const blueStyle = {":hover":{backgroundColor:"#03a9f4", boxShadow:"none"}, backgroundColor:"#4fc3f7", boxShadow:"none",}
const whiteBlueStyle = {":hover":{backgroundColor:"white", boxShadow:"none"}, backgroundColor:"white", border:"1px solid #03a9f4", color:"#03a9f4", boxShadow:"none",}
const greenStyle = {":hover":{backgroundColor:"#7BB972", boxShadow:"none"}, backgroundColor:"#7BB972", boxShadow:"none",}

export const editButton =  (onClick: ()=>void) => (
  <Button variant='contained' sx={{ ...blueStyle, borderRadius: "5px"}} 
      onClick={() => {onClick()}}>
      {"編集"}
      <EditIcon sx={{marginLeft:1.5}}/>
  </Button>
)

interface Props {
  isMobile : boolean;
}


const Company = ({isMobile}: Props) => {
  const { subTitleFontSize, mainFontSize, contentRatio, isXS, isSmall, headerSize } = useContext(MyContext);
  
  const [name, setName] = useState<string>("")
  const [manager_name, setManagerName] = useState<string>("")
  const [phone, setPhone] = useState<string>("")
  const [mail, setMail] = useState<string>("")

  // ルームID
  const [searchParams] = useSearchParams();
  const company_id :string | null = searchParams.get(`companyID`) ?? null

  const { data: companies, queryKey:companies_queryKey, isLoading: isLoading_companies } : UseQueryResult<simple[], Error> & { queryKey: string } = useGetCompanies("companies", company_id);
  const [company, setCompany] = useState<simple | null>(null)
  useEffect(()=>{
    if(companies && companies.length === 1){
      setCompany(companies[0])
    }
  },[companies])

  const putMutationCompany = usePutCompany();
  const deleteMutationCompany = useDeleteCompany();

  const onDelete = () => {
    if(company_id){
      deleteMutationCompany.mutate(company_id,{onSuccess:async()=>{
        window.location.href = "/companies"
      }})
    }
  }

  const getRow = (title:string, value:string, ) : RowProps => {
    return (
      {values:[{value:<>{title}</>}, {value:<>{value}</>}, 
      {
        value:(<>
          {editButton(()=>{})}
        </>),
        align: "right"
      }], rowCSS: {backgroundColor:"white"}}
    )
  }


  return (
    <Stack bgcolor={"snow"} mt={headerSize+"px"} zIndex={2} minHeight={1000} pt={2} pb={5} alignItems={'center'} position={'relative'} spacing={4}>

        <Stack alignItems={"center"} width={contentRatio*100+"%"} zIndex={2} direction={"row"} justifyContent={"space-between"}>
          <Stack onClick={()=>{
            window.location.href="/companies"
          }} sx={{cursor:"pointer"}} spacing={1} direction={"row"} alignItems={"center"}>
            <NavigateBeforeIcon sx={{color:"#383E86"}} />
            <Typography color={"#383E86"} fontWeight={600} fontSize={mainFontSize} letterSpacing={1.2} >
                {"会社一覧"}
            </Typography>
          </Stack>
          <BaseButton height='30px' px={3} color='grey' backgroundColor='white' border='0.5px solid grey' boxShadow='0.5px' text='この会社を削除'
            onClick={()=>{
              onDelete()
            }}
          />
        </Stack>


        {/* メインコンポーネントをここに記述 */}
        <Stack spacing={2} boxShadow={'0 6px 8px rgba(0, 0, 0, 0.15)'} bgcolor={"white"} pt={4} px={6} pb={10} width={contentRatio*100+"%"} alignItems={"center"} >
          <TitleColumn title={company?.name+'様'} title_en={"COMPANY"} fontSize={subTitleFontSize+5}/>

          <BaseTable
            tableCSS={{width: "80%"}}
            head={{values:[{value:<>種別</>}, {value:<>値</>}, {value:<></>}], rowCSS: {backgroundColor:"snow"}}}
            bodies={[
              getRow("会社名", company?.name ?? ""),
              getRow("担当者名", company?.manager_name ?? ""),
              getRow("電話番号", company?.phone ?? ""),
              getRow("メールアドレス", company?.mail ?? "")
            ]}
          />
        </Stack>




       
    </Stack>
  )
}

export default Company;
