import React, { useContext, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Stack } from '@mui/material';
import { MyContext } from '../../router/router';
import { BaseAccordion, BaseButton, BaseTextField } from 'reidea-common';
import { useDeleteSection, usePutSection } from '../API/sections';
import SouthIcon from '@mui/icons-material/South';
import LanguageIcon from '@mui/icons-material/Language';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useQueryClient } from 'react-query';
import { on } from 'stream';
import { useSearchParams } from 'react-router-dom';

interface Props {
    title: string;
    preveiw?: string;
    details: string;
    width?: string | number;
    section_id: string;
}

const TextSectionAccordion: React.FC<Props> = (props) => {

    const { subTitleFontSize, mainFontSize, contentRatio, isXS, isSmall, headerSize } = useContext(MyContext);

    const [searchParams] = useSearchParams();
    const project_id :string | null = searchParams.get(`projectID`) ?? null
    const [open, setOpen] = useState<boolean>(false);
    const [value, setValue] = useState<string>(props.details);
    const putSectionMutation = usePutSection();
    const queryClient = useQueryClient();

    const onChange = async () => {
        try {
            if(project_id){
                await putSectionMutation.mutate({
                    newValues: {
                        project_id: project_id,
                        section_id: props.section_id,
                        text: value
                    },
                },
                {onSuccess: () => { queryClient.invalidateQueries('sections'); }});
            }
        } catch (error) {
            console.error('Failed to update like status:', error);
        }
    }

    useEffect(()=>{
        setValue(props.details);
    },[props.details])

    useEffect(()=>{
        onChange();
    },[value])

    return (
        <Stack p={3} width={props.width ?? "100%"} sx={{backgroundColor:"white", borderRadius:1, boxShadow:'0 4px 6px rgba(0, 0, 0, 0.1)'}}>
            <BaseAccordion
                open={open}
                summary={
                    <Stack direction={"row"} width={"100%"} alignItems={"center"} pb={2} justifyContent={"space-between"} 
                        onClick={()=>{setOpen(!open)}}
                    >
                        <Stack direction={"row"} alignItems={"center"} spacing={1} >
                            <LanguageIcon sx={{fontSize:mainFontSize+2, color:"#383E86"}}></LanguageIcon>
                            <Typography color={"#383E86"} fontWeight={600} fontSize={mainFontSize+2} letterSpacing={1.2} >
                                {props.title}
                            </Typography>
                        </Stack>
                        <KeyboardArrowDownIcon sx={{color:"grey", transform: open ? "rotate(180deg)" : "rotate(0deg)"}} />
                    </Stack>
                }

                preview={
                    <Typography sx={{
                        cursor:"pointer",
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        WebkitLineClamp: 2, // 2行で制限
                        textOverflow: 'ellipsis',
                        fontSize: mainFontSize,
                        lineHeight: 1.2,
                        minHeight: mainFontSize*2*1.2,
                    }}>
                        {props.preveiw}
                    </Typography>
                }
                details={
                    <BaseTextField padding={"0px"} rows={undefined} maxRows={1000} hovered_backgroundColor='transparent' backgroundColor='transparent' placeholder='' value={value} setValue={setValue} />
                }
        />
    </Stack>
    )
}


export default TextSectionAccordion;